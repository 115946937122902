import React from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

class Step1 extends React.Component {
    render() {
        return <>
            <h3>Шаг 1 - исследование</h3>
            <Form>
                <Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>ФИО пациента</Form.Label>
                            <Form.Control placeholder="Введите фамилию, имя и отчество пациента"
                                          value={this.props.patientName}
                                          onChange={(e) => this.props.setPatientName(e.target.value)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Телефон пациента</Form.Label>
                            <Form.Control placeholder="например, +79991234567"
                                          value={this.props.patientPhone}
                                          onChange={(e) => this.props.setPatientPhone(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Комментарии к исследованию</Form.Label>
                            <Form.Control as="textarea" rows="5" value={this.props.studyComment}
                                          onChange={(e) => this.props.setStudyComment(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group className="text-right">
                    <Button type="button" variant="primary" onClick={this.props.nextStep}>Далее</Button>
                </Form.Group>
            </Form>
        </>;
    }
}

export default Step1;
